
.import-legacy-assessements-cotainer {
    height: calc(100vh - 155px);
}

.import-legacy-assessements-cotainer .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.upload-btn {
    margin-left: auto;
}