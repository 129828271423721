html {
  width: 100%;
  /* font-family: "Lato", sans-serif !important; */
}

input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"] {
  /* font-family: "Lato", sans-serif !important; */
  font-size: 12.5px !important;
}

table.datatable thead > tr > th {
  font-size: 12.5px !important;
  /* font-family: "Lato", sans-serif !important; */
}

table.datatable tbody > tr > td,
table.datatable tbody > tr > td a {
  font-size: 12.5px !important;
  /* font-family: "Lato", sans-serif !important; */
}

label.control-label,
.pink-btn,
#datatable-columns-settings-wrapper button,
.top-bar .searchbox input[type="search"],
.logged-user .dropdown-menu li a,
#left-sidebar ul.main-menu li > a,
#left-sidebar ul.main-menu li > a span,
.breadcrumb li.active,
.breadcrumb li.active span,
p,
div {
  font-size: 12.5px;
  /* font-family: "Lato", sans-serif !important; */
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-select,
.MuiTablePagination-displayedRows {
  font-size: 12.5px !important;
  /* font-family: "Lato", sans-serif !important; */
}

.MuiDataGrid-columnSeparator--sideRight {
  visibility: initial !important;
}

.vertical-nav-menu {
  margin-top: 7px;
}

.app-sidebar,
.app-header__logo {
  width: 280px;
}

@media screen and (min-width: 576px) {
  .right-align {
    text-align: right;
    padding-right: 0px;
  }
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  position: absolute;
  border: 1px solid #999;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  background: white;
  margin-top: 37px;
  z-index: 1055;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestions li:hover {
  background-color: whitesmoke;
  cursor: pointer;
  font-weight: 700;
}

.col-form-label.required:before {
  content: "*";
  color: red;
  margin-right: 3px;
}

.form-checkbox {
  vertical-align: -webkit-baseline-middle;
}

.form-label-control {
  padding-left: 0px;
}

.vendor-contact-list-grid-container {
  height: 400px;
  width: 100%;
}

.assessor-popup-list-grid-container {
  height: 400px;
  width: 100%;
}

.notes-grid {
  height: 300px;
  width: 100%;
}

.activity-grid {
  height: 400px;
  width: 100%;
}

.vendor-manager-modal {
  max-width: 980px !important;
}

.post-btn-row {
  margin-top: 12px;
  margin-right: 0px;
  margin-bottom: 12px;
}

.loading-indicator:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.2);
  /* backdrop-filter: blur(1px); */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1300;
}

/*
.loading-indicator:after {
    content: 'Loading';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.5rem;
} */

.loader {
  color: #495057;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  z-index: 1100;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: fixed;
  top: 30%;
  left: 46%;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.notes-custom {
  width: 12.5%;
}

.vendor-contacts-submit-grid {
  height: 300px;
  width: 100%;
  margin-top: 12px;
}

/*
.vendor-submit-stepper a{
    margin-top: 0px !important;
    font-size: 14px !important;
} */

.vendor-submit-stepper {
  margin-bottom: 24px;
}

.vendor-submit-stepper > div > div > div > div:first-child {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.vendor-contacts-submit-sections-grid {
  margin-top: 12px;
  height: 100%;
  height: 600px;
}

.vendor-contacts-submit-sections-grid .MuiDataGrid-footerContainer {
  display: none;
}

.custom-msg-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.refresh-icon {
  margin-left: 24px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
}

.react-date-picker {
  width: 100%;
  height: 36px;
}

.react-date-picker__wrapper {
  border-radius: 4px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: black;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  /*your box-shadow*/
  -webkit-text-fill-color: black;
}

.back-button-svg:focus {
  outline: none !important;
}

.eye-button svg:focus {
  outline: none !important;
}

.section-heading {
  font-size: 0.88rem;
  font-weight: 600;
}

.third-party-detail-view .col-form-label,
.business-add-card .col-form-label,
.remediation-card .col-form-label {
  font-weight: 700;
}

.center-align-col-header {
  text-align: center;
}

.pre-assessment-form-modal #RFS-Label {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 105px;
  overflow: hidden;
}

.pre-assessment-form-modal .form-label {
  font-weight: 700;
}

.span-input-box {
  border: 1px solid #ced4da;
  display: inline-block;
  padding: 6px 12px;
  width: 200px;
  border-radius: 5px;
  height: 32px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.comments_report {
  display: inline-block;
  font-weight: normal;
  margin: 0px;
  color: black;
}
.hide {
  display: none;
  visibility: hidden;
}

.app-page-title {
  margin: -1.5rem -1.5rem 1rem;
  padding-bottom: 0.1rem;
  margin-bottom: 0.1rem;
}

.mdxeditor {
  border: 1px solid #ccc;
  border-radius: 6px;
}

.mdxeditor p {
  color: #11181c !important;
}

[data-radix-popper-content-wrapper] {
  z-index: 9999 !important;
}

[data-lexical-editor] {
  max-height: 310px;
  overflow: hidden;
  overflow-y: auto;
}

.domain-summary-card-row {
  align-items: center;
  height: 100%;
}

.domain-report-cards-icon-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.no-text-available-nist {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 16px;
  font-weight: 500;
}

.issue-severity-legend {
  list-style: none;
  display: inline-block;

  padding: 20px 16px 0px;
  margin: 0px;
}
.issue-severity-legend li {
  float: left;
  margin-right: 10px;
}
.issue-severity-legend span {
  float: left;
  width: 16px;
  height: 16px;
  margin: 2px;
  margin-right: 4px;
  border-radius: 50%;
}
.issue-severity-legend .your-score {
  background-color: #edc951;
}
.issue-severity-legend .target-score {
  background-color: #cc333f;
}

.disabled-container {
  display: none;
}

.business-add-card {
  max-height: 600px;
  overflow-y: auto;
}

.fa-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.rating-summary-domain-question-answer-select {
  max-width: 75px;
  display: inline-block;
  float: right;
  line-height: normal;
  text-align: center;
}

.detail-data-elements-badges-col {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: calc(0.375rem + 1px);
  padding-top: calc(0.375rem + 1px);
}

.badge-close-btn:focus {
  box-shadow: none !important;
}

.editquestionpopup .col-form-label {
  font-weight: 600;
  display: block;
}

.completed.new-assessment-request-step {
  span.completed::after {
    content: "\2713";
    display: inline-block;
    color: #fff;
    font-weight: bolder;
  }
}

.remediation-details-action-dropdown .dropdown-menu {
  min-width: 190px;
}
.remediation-details-action-dropdown .dropdown-menu::before,
.remediation-details-action-dropdown .dropdown-menu::after {
  left: 161px !important;
}

.remediation-evidence-icon:focus {
  outline: none !important;
}

.input-group .input-box {
  flex: 1;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 1px solid #ccc;
  padding: 11px;
  user-select: none;
}

.input-group .input-box .placeholder {
  color: #a28e8e;
  font-weight: unset;
}

.input-group .input-box label {
  margin-bottom: 0px !important;
}

/* button.new-assessment-request-step:disabled {
    background-color: rgb(255 255 255 / 80%);
  } */

.tprm-donut-graph .legend-item-label {
  display: inline-block;
  min-width: 120px;
  font-size: 16px !important;
}

.tprm-donut-graph .legend-item-value {
  display: inline-block;
  min-width: 25px;
  text-align: right;
  font-size: 16px !important;
}

.tprm-donut-graph .apexcharts-legend {
  justify-content: center !important;
  gap: 6px;
}

.tprm-donut-graph h5 {
  text-align: center;
  margin-top: 140px;
}

.vendor-multiple-submit-sections-grid {
  margin-top: 12px;
  height: 100%;
  height: 500px;
}

.vendor-multiple-submit-sections-grid .MuiDataGrid-footerContainer {
  display: none;
}

.file-drop-zone {
  display: grid;
  place-content: center;
  border: 4px dashed rgb(211 211 211 / 40%);
  padding: 30px 10px;
  text-align: center;
  border-radius: 16px;
}

.file-label {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 8px;
  font-weight: normal;
}

.inline-p {
  display: inline-block;
  font-size: 10px;
  margin: 5px 0px;
}

.inline-text {
  font-size: 10px;
}
